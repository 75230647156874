<template>
  <div class="registerBox">
    <van-nav-bar :border="false" @click-left="$parent.goBackAccount" @click-right="goLogin" fixed placeholder>
      <template #left>
        <van-icon name="cross" size="24" />
      </template>
      <template #right>
        <van-button size="small" plain type="info" class="fs26">已有账户？</van-button>
      </template>
    </van-nav-bar>
    <Space :height="147"></Space>
    <div class="contentBox">
      <TextBox :font-size="44" color="title" :bold="5">注册</TextBox>
      <Space :height="12"></Space>
      <TextBox color="intro">立刻注册属于您的账号</TextBox>
      <Space :height="58"></Space>
      <van-field :value="country" readonly class="border" @click="$refs.selectCountry.init()">
        <template #button>
          <van-icon name="play" style="transform: rotate(90deg);" color="#9EA2B5" />
        </template>
      </van-field>
      <Space :height="29"></Space>
      <van-field
        v-model="phone"
        type="number"
        placeholder="请输入注册手机号"
        class="border"
        :label="'+' + countryCode"
        :label-width="$pxToVw(90)"
        :error="!!phoneError"
        :error-message="phoneError"
      />
      <Space :height="29"></Space>
      <van-field v-model="code" type="number" placeholder="请输入短信验证码" class="border errorRight15">
        <template #button>
          <SendCode url="/v1/auth/sendRegisterCode" :area-code="countryCode" :phone="phone"></SendCode>
        </template>
      </van-field>
      <Space :height="29"></Space>
      <van-field v-model="password" type="password" placeholder="请设置6-20位登录密码" class="border" />
      <Space :height="29"></Space>
      <van-field v-model="passwordAgain" type="password" placeholder="请再次确认登录密码" class="border" />
      <Space :height="29"></Space>
      <van-field v-model="inviteCode" placeholder="请输入邀请码" class="border" />
      <Space :height="83"></Space>
      <div class="tipsBox">
        注册即代表您同意
        <van-button size="small" plain type="info" @click="goUserAgreement">《用户服务协议》</van-button>
      </div>
      <Space :height="31"></Space>
      <van-button type="primary" block @click="submit" :loading="$store.state.submitLoading">注册</van-button>
      <Space :height="41"></Space>
    </div>
    <SelectCountry ref="selectCountry" :callback="countryCallback"></SelectCountry>
  </div>
</template>
<script>
import qs from 'qs';
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: [],
  data() {
    return {
      phone: '',
      phoneError: '',
      code: '',
      password: '',
      passwordAgain: '',
      inviteCode: '',
      country: '中国',
      countryCode: 86,
    };
  },
  // created () {},
  // mounted () {},
  methods: {
    countryCallback(res) {
      this.country = res.zh;
      this.countryCode = res.code;
    },
    goLogin() {
      let params = '';
      if (this.$route.query) {
        params = `?${qs.stringify(this.$route.query)}`;
      }
      this.$router.push(`/account/login${params}`);
    },
    goUserAgreement() {
      this.$router.push('/other/userAgreement');
    },
    submit: _.debounce(function() {
      if (!this.phone) {
        this.phoneError = '手机号不能为空';
        return;
      } else {
        this.phoneError = '';
      }
      if (this.countryCode === 86) {
        if (!/^1[3456789]\d{9}$/.test(this.phone)) {
          this.phoneError = '请输入正确手机号';
          return;
        }
      }
      if (!this.code) {
        this.$toast('验证码不能为空');
        return;
      }
      if (!this.password) {
        this.$toast('密码不能为空');
        return;
      }
      if (this.password !== this.passwordAgain) {
        this.$toast('输入的两次密码不一致');
        return;
      }
      if (!this.inviteCode) {
        this.$toast('邀请码不能为空');
        return;
      }
      this.$store.commit('setSubmitLoading', true);
      this.$http('post', '/v1/auth/register', {
        areaCode: this.countryCode,
        phone: this.phone,
        code: this.code,
        password: this.password,
        validatePassword: this.passwordAgain,
        inviteCode: this.inviteCode,
      })
        .then(res => {
          let that = this;
          this.$toast('注册成功');
          that.$router.push('/account/login');
        })
        .all(() => {
          this.$store.commit('setSubmitLoading', false);
        });
    }),
  },
  // watch: {},
  computed: {
    // phoneProcess() {
    //   if (this.countryCode === 86) {
    //     return this.phone;
    //   } else {
    //     return this.countryCode + this.phone;
    //   }
    // },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.selectBox {
  width: 148px;
  height: 90px;
  border-radius: 10px;
  border: 1px solid @border-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  .text {
    color: @title-color;
  }
  .icon {
    margin-left: 16px;
  }
}
.tipsBox {
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
